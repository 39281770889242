.stats {
  background: var(--gradient);
  color: var(--white);
  position: relative;
  overflow: hidden;
}

.stats::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  gap: clamp(1.5rem, 4vw, 2rem);
  padding: clamp(1rem, 3vw, 2rem);
  max-width: 1200px;
  margin: 0 auto;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: clamp(1.5rem, 4vw, 2rem);
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.stat-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.stat-icon {
  width: clamp(50px, 15vw, 70px);
  height: clamp(50px, 15vw, 70px);
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  transition: all 0.3s ease;
}

.stat-card:hover .stat-icon {
  background: rgba(255, 255, 255, 0.25);
  transform: scale(1.1);
}

.stat-icon i {
  font-size: clamp(1.5rem, 4vw, 2rem);
  color: var(--white);
}

.stat-number {
  font-size: clamp(2rem, 6vw, 3rem);
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: clamp(1rem, 3vw, 1.1rem);
  opacity: 0.9;
  margin-bottom: 0.5rem;
}

.stat-description {
  font-size: clamp(0.875rem, 2.5vw, 0.95rem);
  opacity: 0.7;
  line-height: 1.6;
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
  .stats-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .stats-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .stat-card {
    padding: 1.25rem;
  }

  .stat-icon {
    width: 45px;
    height: 45px;
    margin-bottom: 0.75rem;
  }

  .stat-number {
    margin-bottom: 0.25rem;
  }

  .stat-description {
    display: none;
  }
}
