.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1rem 0;
  transition: all 0.3s ease;
  background-color: transparent;
}

.header.scrolled {
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.8rem 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
}

.nav {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-list {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.nav-list a {
  text-decoration: none;
  color: var(--text);
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

.nav-list a::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--primary);
  transition: width 0.3s ease;
}

.nav-list a:hover::after {
  width: 100%;
}

.nav-cta {
  padding: 0.6rem 1.5rem !important;
}

.mobile-menu-btn {
  display: none;
  flex-direction: column;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  z-index: 1001;
}

.mobile-menu-btn span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: var(--text);
  transition: all 0.3s ease;
}

.mobile-menu-btn.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.mobile-menu-btn.active span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-btn.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 768px) {
  .mobile-menu-btn {
    display: flex;
  }

  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 400px;
    height: 100vh;
    background-color: var(--white);
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    transition: right 0.3s ease;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  }

  .nav.active {
    right: 0;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .nav-list a {
    font-size: 1.2rem;
  }

  .nav-cta {
    margin-top: 2rem;
  }
}

@media (max-width: 480px) {
  .logo a {
    font-size: 1.2rem;
  }

  .nav {
    width: 100%;
    max-width: none;
  }
}
