.about {
  padding: 100px 2rem 4rem;
}

.about-header {
  text-align: center;
  margin-bottom: 4rem;
}

.about-header h1 {
  font-size: 2.5rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.about-header p {
  font-size: 1.2rem;
  color: #666;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.profile-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  margin-bottom: 4rem;
}

.profile-image {
  position: relative;
}

.profile-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-info h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.profile-info p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.8;
}

.expertise h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.expertise ul {
  list-style-type: none;
  padding: 0;
}

.expertise ul li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  font-size: 1.1rem;
  color: #666;
}

.expertise ul li::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: 0;
}

.achievements-section {
  margin-bottom: 4rem;
}

.achievements-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--text-color);
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.achievement-card {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.achievement-card:hover {
  transform: translateY(-5px);
}

.achievement-card i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.achievement-card h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.achievement-card p {
  color: #666;
}

.mission-section {
  text-align: center;
  padding: 4rem 0;
  background-color: var(--light-bg);
  border-radius: 10px;
}

.mission-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.mission-section p {
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}

@media (max-width: 968px) {
  .profile-section {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .profile-image {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .about {
    padding: 80px 1rem 2rem;
  }

  .about-header h1 {
    font-size: 2rem;
  }

  .profile-info h2,
  .achievements-section h2,
  .mission-section h2 {
    font-size: 1.8rem;
  }
}
