.hero {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding-top: var(--navbar-height);
  margin-top: calc(var(--navbar-height) * -1);
}
.hero-bg {
  padding: 8rem 0 4rem;
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.1) 0%, rgba(37, 99, 235, 0.05) 100%);
}

.hero-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.hero-content {
  max-width: 600px;
}

.hero-content h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.hero-content p {
  font-size: 1.1rem;
  color: var(--text-light);
  margin-bottom: 2rem;
  line-height: 1.8;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(100, 116, 139, 0.2);
}

.stat-item h3 {
  font-size: 2rem;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.stat-item p {
  font-size: 0.9rem;
  margin: 0;
}

.hero-image {
  position: relative;
  padding-top: 2rem;
}

.image-container {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(37, 99, 235, 0.2);
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.image-container:hover img {
  transform: scale(1.05);
}

.experience-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--primary);
  color: var(--white);
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  transform: translateX(30%) translateY(-30%);
  box-shadow: 0 10px 20px rgba(37, 99, 235, 0.3);
}

.experience-badge span {
  font-size: 2rem;
  font-weight: 700;
  display: block;
}

.experience-badge p {
  font-size: 0.9rem;
  margin: 0;
}

.hero-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  line-height: 0;
}

.hero-shape svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .hero-content h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-cta {
    justify-content: center;
  }

  .hero-stats {
    justify-content: center;
  }

  .hero-image {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-cta {
    flex-direction: column;
  }

  .hero-stats {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .experience-badge {
    display: none;
  }
}
