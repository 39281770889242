.testimonials {
  background-color: var(--light);
  position: relative;
  overflow: hidden;
}

.testimonials::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient);
  opacity: 0.03;
  pointer-events: none;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  gap: clamp(1.5rem, 4vw, 2rem);
  padding: clamp(1rem, 3vw, 2rem);
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: var(--white);
  border-radius: 20px;
  padding: clamp(1.5rem, 4vw, 2rem);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.testimonial-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.testimonial-card:hover::before {
  opacity: 1;
}

.testimonial-content {
  margin-bottom: 1.5rem;
  position: relative;
  flex-grow: 1;
}

.quote-icon {
  font-size: clamp(1.5rem, 4vw, 2rem);
  color: var(--primary);
  opacity: 0.2;
  position: absolute;
  top: -10px;
  left: -5px;
}

.testimonial-content p {
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  color: var(--text);
  line-height: 1.6;
  position: relative;
  z-index: 1;
  margin-top: 1rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
}

.author-image {
  width: clamp(45px, 12vw, 60px);
  height: clamp(45px, 12vw, 60px);
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--light);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.testimonial-card:hover .author-image {
  border-color: var(--primary);
  transform: scale(1.05);
}

.author-info {
  flex-grow: 1;
}

.author-info h4 {
  font-size: clamp(1rem, 3vw, 1.1rem);
  color: var(--dark);
  margin-bottom: 0.25rem;
}

.author-info p {
  font-size: clamp(0.8rem, 2.5vw, 0.9rem);
  color: var(--text-light);
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
  .testimonials-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .testimonials {
    padding: 3rem 0;
  }

  .testimonial-card {
    padding: 1.25rem;
  }

  .quote-icon {
    font-size: 1.25rem;
    top: -5px;
  }
}

@media (max-width: 576px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .testimonial-content {
    margin-bottom: 1rem;
  }

  .author-image {
    width: 40px;
    height: 40px;
  }
}
