.services {
  background-color: var(--light);
  position: relative;
  overflow: hidden;
}

.services::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient);
  opacity: 0.05;
  pointer-events: none;
}

.section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  padding: 0 1rem;
}

.section-header h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--dark);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: var(--primary);
  border-radius: 2px;
}

.section-header p {
  color: var(--text-light);
  font-size: clamp(1rem, 3vw, 1.1rem);
  max-width: 600px;
  margin: 0 auto;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  gap: clamp(1rem, 3vw, 2rem);
  padding: 0 1rem;
}

.service-card {
  background: var(--white);
  border-radius: 20px;
  padding: clamp(1.5rem, 4vw, 2rem);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.service-card:hover::before {
  opacity: 0.05;
}

.service-icon {
  width: clamp(50px, 15vw, 60px);
  height: clamp(50px, 15vw, 60px);
  background: rgba(37, 99, 235, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.service-card:hover .service-icon {
  background: var(--primary);
  transform: rotate(-10deg);
}

.service-icon i {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  color: var(--primary);
  transition: all 0.3s ease;
}

.service-card:hover .service-icon i {
  color: var(--white);
}

.service-card h3 {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  color: var(--dark);
  margin-bottom: 1rem;
}

.service-card p {
  color: var(--text-light);
  margin-bottom: 1.5rem;
  font-size: clamp(0.9rem, 3vw, 1rem);
  flex-grow: 1;
}

.service-features {
  list-style: none;
  margin-bottom: 1.5rem;
}

.service-features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: clamp(0.875rem, 2.5vw, 0.95rem);
  color: var(--text);
}

.service-features li i {
  color: var(--success);
  font-size: 0.875rem;
}

.service-cta {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  transition: all 0.3s ease;
  margin-top: auto;
}

.service-cta:hover {
  color: var(--primary-dark);
  gap: 0.75rem;
}

.service-cta i {
  font-size: 0.875em;
  transition: transform 0.3s ease;
}

.service-cta:hover i {
  transform: translateX(3px);
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .section-header {
    margin-bottom: 3rem;
  }
}

@media (max-width: 768px) {
  .services {
    padding: 3rem 0;
  }
  
  .section-header {
    margin-bottom: 2rem;
  }
  
  .service-card {
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .service-icon {
    width: 45px;
    height: 45px;
    margin-bottom: 1rem;
  }
  
  .service-features {
    margin-bottom: 1rem;
  }
}
