.floating-buttons {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
}

.scroll-top-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--primary);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scroll-top-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.scroll-top-button:active {
  transform: translateY(-1px);
}

.whatsapp-button {
  background-color: #25D366;
}

.whatsapp-button:hover {
  background-color: #1da851;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(37, 211, 102, 0.3);
}

/* Responsive styles */
@media (max-width: 768px) {
  .floating-buttons {
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .scroll-top-button {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .whatsapp-button {
    padding: 10px 16px;
  }

  .whatsapp-button i {
    margin-right: 0;
    font-size: 1.8rem;
  }
}

/* Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.whatsapp-button {
  animation: pulse 2s infinite;
}

.whatsapp-button:hover {
  animation: none;
}
