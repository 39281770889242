.whatsapp-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.3);
  transition: all 0.3s ease;
  z-index: 9999;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
}

.whatsapp-button i {
  font-size: 2rem;
  color: white;
}

.whatsapp-button:hover {
  transform: translateY(-5px) translateZ(0);
  box-shadow: 0 6px 16px rgba(37, 211, 102, 0.4);
}

.whatsapp-button:active {
  transform: translateY(-2px) translateZ(0);
}

.whatsapp-button .tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 10000;
}

.whatsapp-button .tooltip::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px 6px 0;
  border-style: solid;
  border-color: #333 transparent transparent;
}

.whatsapp-button:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -45px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .whatsapp-button {
    width: 50px;
    height: 50px;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .whatsapp-button i {
    font-size: 1.75rem;
  }
}

/* Animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
}

.whatsapp-button {
  animation: pulse 2s infinite;
}
