.contact {
  background-color: var(--light);
  position: relative;
  overflow: hidden;
  padding: 0 0 4rem 0;
}

.contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient);
  opacity: 0.03;
  pointer-events: none;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: clamp(1rem, 3vw, 2rem);
  margin-bottom: 2rem;
}

.contact-card {
  background: var(--white);
  padding: clamp(1.5rem, 4vw, 2rem);
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.contact-card i {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--primary);
  margin-bottom: 1rem;
}

.contact-card h3 {
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  color: var(--dark);
  margin-bottom: 0.5rem;
}

.contact-card p {
  color: var(--text-light);
  margin-bottom: 1rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.contact-link {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.contact-link:hover {
  color: var(--primary-dark);
  transform: translateX(5px);
}

.contact-cta {
  background: var(--gradient);
  padding: clamp(2rem, 5vw, 3rem);
  border-radius: 20px;
  text-align: center;
  color: var(--white);
  margin-top: 2rem;
}

.contact-cta h3 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin-bottom: 1rem;
}

.contact-cta p {
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: clamp(1rem, 3vw, 2rem);
  flex-wrap: wrap;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background: var(--white);
  color: var(--primary);
}

.cta-button.primary:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-3px);
}

.cta-button.secondary {
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
  border: 2px solid var(--white);
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

/* Responsive Breakpoints */
@media (max-width: 768px) {
  .contact-info {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .contact-card {
    padding: 1.5rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contact-card i {
    font-size: 1.75rem;
  }

  .contact-cta {
    padding: 1.5rem;
  }
}
